// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  runTransaction,
  arrayUnion,
  updateDoc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { getDatabase, ref, child, get } from "firebase/database";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// deployment database
const firebaseConfig = {
  apiKey: "AIzaSyDEdzOlAr8bTlo_PG7imPGmAuHVbC0mTiI",
  authDomain: "fantasy-valorant-7fa02.firebaseapp.com",
  projectId: "fantasy-valorant-7fa02",
  storageBucket: "fantasy-valorant-7fa02.appspot.com",
  messagingSenderId: "540540896036",
  appId: "1:540540896036:web:7ca1f3e9e270506cd2a9b1",
  measurementId: "G-5EN4K7GM2R",
};

// test database
// const firebaseConfig = {
//   apiKey: "AIzaSyB3MBUKmjrNmLsNy52gVnyayVmAZWR2YDU",
//   authDomain: "fantasy-valorant-test.firebaseapp.com",
//   databaseURL: "https://fantasy-valorant-test-default-rtdb.firebaseio.com",
//   projectId: "fantasy-valorant-test",
//   storageBucket: "fantasy-valorant-test.appspot.com",
//   messagingSenderId: "159266701464",
//   appId: "1:159266701464:web:f20e864cd2de1b789990ed",
//   measurementId: "G-GMLB6TGK14"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const auth = getAuth();
export const provider = new GoogleAuthProvider();

export const getTestPlayer = async () => {
  const dbRef = ref(getDatabase());
  await get(child(dbRef, `Players`))
    .then((snapshot) => {
      snapshot.val().forEach((player) => {
        setDoc(doc(db, "Players", player.player_name), {
          nation: player.nation,
          player_name: player.player_name,
          roles: player.roles,
          price: player.price,
          team_name: player.team_name,
          total_points: 0,
          picked: 0
        });
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const updateUserPoints = async (uid, points) => {
//   const userRef = doc(db, "Users", uid);
//   console.log("updating user points")
//   await updateDoc(userRef, {
//     "points": points,
//   });
// };


export const howManyUsersPerPlayer = async () => {
  //var playersList = [];
  const playersSnapshot = await getDocs(collection(db, "Players"));
  var dict = {}
  playersSnapshot.forEach((player) => {
    //playersList.push(doc.data());
    var num = checkWhichUsersHavePlayer(player.data().player_name, player.data().team_name)
    num.then((value) => {
      dict[player.data().player_name] = value
      // console.log(player.data().player_name)
      //console.log("\"" + player.data().player_name + "\"" + " : " + dict[player.data().player_name])
      // console.log("value is " + value)
    })
  });
  //console.log(dict["yay"])
}

export const checkBabyJandEc1s = async () => {
  const usersRef = collection(db, "Users");
  const q1 = query(usersRef, where("user_team.controller_1", "==", { name: "ec1s", team: "100 Thieves" }), where("user_team.sentinel_1", "==", { name: "BabyJ", team: "100 Thieves" }))
  const q2 = query(usersRef, where("user_team.controller_1", "==", { name: "ec1s", team: "100 Thieves" }), where("user_team.sentinel_2", "==", { name: "BabyJ", team: "100 Thieves" }))
  const q3 = query(usersRef, where("user_team.controller_2", "==", { name: "ec1s", team: "100 Thieves" }), where("user_team.sentinel_1", "==", { name: "BabyJ", team: "100 Thieves" }))
  const q4 = query(usersRef, where("user_team.controller_2", "==", { name: "ec1s", team: "100 Thieves" }), where("user_team.sentinel_2", "==", { name: "BabyJ", team: "100 Thieves" }))

  const q1Snapshot = await getDocs(q1)
  q1Snapshot.forEach((doc) => {
    console.log(doc.data().display_team_name + ": " + doc.id)
  })
  const q2Snapshot = await getDocs(q2)
  q2Snapshot.forEach((doc) => {
    console.log(doc.data().display_team_name + ": " + doc.id)
  })
  const q3Snapshot = await getDocs(q3)
  q3Snapshot.forEach((doc) => {
    console.log(doc.data().display_team_name + ": " + doc.id)
  })
  const q4Snapshot = await getDocs(q4)
  q4Snapshot.forEach((doc) => {
    console.log(doc.data().display_team_name + ": " + doc.id)
  })
}

export const checkWhichUsersHavePlayer = async (player_name, team_name) => {
  const usersRef = collection(db, "Users");
  const q1 = query(usersRef, where("user_team.controller_1", "==", { name: player_name, team: team_name }))
  const q2 = query(usersRef, where("user_team.controller_2", "==", { name: player_name, team: team_name }))
  const q3 = query(usersRef, where("user_team.duelist_1", "==", { name: player_name, team: team_name }))
  const q4 = query(usersRef, where("user_team.duelist_2", "==", { name: player_name, team: team_name }))
  const q5 = query(usersRef, where("user_team.initiator_1", "==", { name: player_name, team: team_name }))
  const q6 = query(usersRef, where("user_team.initiator_2", "==", { name: player_name, team: team_name }))
  const q7 = query(usersRef, where("user_team.sentinel_1", "==", { name: player_name, team: team_name }))
  const q8 = query(usersRef, where("user_team.sentinel_2", "==", { name: player_name, team: team_name }))

  const q1Snapshot = await getDocs(q1);
  var q1List = [];
  var count = 0;
  q1Snapshot.forEach((doc) => {
    q1List.push(doc)
    count++
    // console.log(doc.data().display_team_name)
    //console.log(doc.id)
  })
  const q2Snapshot = await getDocs(q2);
  q2Snapshot.forEach((doc) => {
    q1List.push(doc)
    count++
    // console.log(doc.data().display_team_name)
    console.log(doc.id)
  })
  const q3Snapshot = await getDocs(q3);
  q3Snapshot.forEach((doc) => {
    q1List.push(doc)
    count++
    // console.log(doc.data().display_team_name)
  })
  const q4Snapshot = await getDocs(q4);
  q4Snapshot.forEach((doc) => {
    q1List.push(doc)
    count++
    // console.log(doc.data().display_team_name)
  })
  const q5Snapshot = await getDocs(q5);
  q5Snapshot.forEach((doc) => {
    q1List.push(doc)
    count++
    // console.log(doc.data().display_team_name)
  })
  const q6Snapshot = await getDocs(q6);
  q6Snapshot.forEach((doc) => {
    q1List.push(doc)
    count++
    // console.log(doc.data().display_team_name)
  })
  const q7Snapshot = await getDocs(q7);
  q7Snapshot.forEach((doc) => {
    q1List.push(doc)
    count++
    // console.log(doc.data().display_team_name)
  })
  const q8Snapshot = await getDocs(q8);
  q8Snapshot.forEach((doc) => {
    q1List.push(doc)
    count++
    // console.log(doc.data().display_team_name)
  })
  //console.log(count + " for " + player_name)
  //console.log(q1List.length + " people have " + player_name)
  //return(q1List.length)
  return count

}

export const resetAllPlayerTeamNamesUnderUserTeam = async () => {
  const userSnapshot = await getDocs(collection(db, "Users"))
  userSnapshot.forEach((doc) => {
    let p1Name = doc.data().user_team.controller_1.name
    let p1TeamName = getCorrectTeamName(p1Name)
    updateUserDoc(1, doc.id, p1TeamName)

  })
}

export const getCorrectTeamName = async (playerName) => {
  const playerRef = doc(db, "Players", playerName)
  const playerSnap = await getDoc(playerRef)
  if (playerSnap.exists()) {
    //console.log(playerSnap.data().team_name)
    return playerSnap.data().team_name
  }


}

export const updateEnvyPlayers = async () => {
  // const playersRef = collection(db, "Players")
  const userRef = collection(db, "Users")
  // const q1 = query (playersRef, where("team_name", "==", "Envy"))
  // const q1Snapshot = await getDocs(q1);
  // q1Snapshot.forEach((doc) => {
  //   console.log(doc.data())
  // })
  const q1 = query (userRef, where("user_team.controller_1.team", "==", "Envy" ))
  const q2 = query (userRef, where("user_team.controller_2.team", "==", "Envy" ))
  const q3 = query (userRef, where("user_team.initiator_1.team", "==", "Envy" ))
  const q4 = query (userRef, where("user_team.initiator_2.team", "==", "Envy" ))
  const q5 = query (userRef, where("user_team.duelist_1.team", "==", "Envy" ))
  const q6 = query (userRef, where("user_team.duelist_2.team", "==", "Envy" ))
  const q7 = query (userRef, where("user_team.sentinel_1.team", "==", "Envy" ))
  const q8 = query (userRef, where("user_team.sentinel_2.team", "==", "Envy" ))

  const q1Snapshot = await getDocs(q1);
  q1Snapshot.forEach((doc) => {
    console.log(doc.data())
  })

  const q2Snapshot = await getDocs(q2);
  q2Snapshot.forEach((doc) => {
    console.log(doc.data())
  })

  const q3Snapshot = await getDocs(q3);
  q3Snapshot.forEach((doc) => {
    console.log(doc.data())
  })

  const q4Snapshot = await getDocs(q4);
  q4Snapshot.forEach((doc) => {
    console.log(doc.data())
  })

  const q5Snapshot = await getDocs(q5);
  q5Snapshot.forEach((doc) => {
    console.log(doc.data())
  })

  const q6Snapshot = await getDocs(q6);
  q6Snapshot.forEach((doc) => {
    console.log(doc.data())
  })

  const q7Snapshot = await getDocs(q7);
  q7Snapshot.forEach((doc) => {
    console.log(doc.data())
  })

  const q8Snapshot = await getDocs(q8);
  q8Snapshot.forEach((doc) => {
    console.log(doc.data())
  })
}

export const fixAllTeamName = async () => {
  const querySnapshot = await getDocs(collection(db, "Users"))
  querySnapshot.forEach((doc) => {
    let p1 = getCorrectTeamName(doc.data().user_team.controller_1.name)
    p1.then((value) => {
      //console.log(value)
      updateUserDoc(1, doc.id, value)
    })

    let p2 = getCorrectTeamName(doc.data().user_team.controller_2.name)
    p2.then((value) => {
      //console.log(value)
      updateUserDoc(2, doc.id, value)
    })

    let p3 = getCorrectTeamName(doc.data().user_team.initiator_1.name)
    p3.then((value) => {
      //console.log(value)
      updateUserDoc(3, doc.id, value)
    })

    let p4 = getCorrectTeamName(doc.data().user_team.initiator_2.name)
    p4.then((value) => {
      //console.log(value)
      updateUserDoc(4, doc.id, value)
    })

    let p5 = getCorrectTeamName(doc.data().user_team.duelist_1.name)
    p5.then((value) => {
      //console.log(value)
      updateUserDoc(5, doc.id, value)
    })

    let p6 = getCorrectTeamName(doc.data().user_team.duelist_2.name)
    p6.then((value) => {
      //console.log(value)
      updateUserDoc(6, doc.id, value)
    })

    let p7 = getCorrectTeamName(doc.data().user_team.sentinel_1.name)
    p7.then((value) => {
      //console.log(value)
      updateUserDoc(7, doc.id, value)
    })

    let p8 = getCorrectTeamName(doc.data().user_team.sentinel_2.name)
    p8.then((value) => {
      //console.log(value)
      updateUserDoc(8, doc.id, value)
    })
  })
}

export const updateUserDoc = async (position, id, teamName) => {
  //console.log("id is", id, " and team to set as is ", teamName)
  if (position === 1) {
    const docRef = doc(db, "Users", id)
    await updateDoc(docRef, {"user_team.controller_1.team": teamName})
  } else if (position === 2) {
    const docRef = doc(db, "Users", id)
    await updateDoc(docRef, {"user_team.controller_2.team": teamName})
  } else if (position === 3) {
    const docRef = doc(db, "Users", id)
    await updateDoc(docRef, {"user_team.initiator_1.team": teamName})
  } else if (position === 4) {
    const docRef = doc(db, "Users", id)
    await updateDoc(docRef, {"user_team.initiator_2.team": teamName})
  } else if (position === 5) {
    const docRef = doc(db, "Users", id)
    await updateDoc(docRef, {"user_team.duelist_1.team": teamName})
  } else if (position === 6) {
    const docRef = doc(db, "Users", id)
    await updateDoc(docRef, {"user_team.duelist_2.team": teamName})
  } else if (position === 7) {
    const docRef = doc(db, "Users", id)
    await updateDoc(docRef, {"user_team.sentinel_1.team": teamName})
  } else if (position === 8) {
    const docRef = doc(db, "Users", id)
    await updateDoc(docRef, {"user_team.sentinel_2.team": teamName})
  }

}


// export const updateUserPlayerTeamName = async (name, uid) => {
//     var userRef = doc(db, "Users", uid)
//     try {
//         await runTransaction(db, async (transaction) => {
//             const userDoc = await transaction.get(userRef);
//             if (!userDoc.exists()) {
//                 throw "DNE"
//             }
//             transaction.update(userDoc, { "user_team.controller_1.team" : name })

//         })
//     } catch (e) {
//         console.log(e)
//     }
// }

export const updateAllUserPoints = async (player_name, team_name, newPoints) => {
  console.log(player_name + ": " + newPoints)
  const usersRef = collection(db, "Users");
  const q1 = query(usersRef, where("user_team.controller_1", "==", { name: player_name, team: team_name }))
  const q2 = query(usersRef, where("user_team.controller_2", "==", { name: player_name, team: team_name }))
  const q3 = query(usersRef, where("user_team.duelist_1", "==", { name: player_name, team: team_name }))
  const q4 = query(usersRef, where("user_team.duelist_2", "==", { name: player_name, team: team_name }))
  const q5 = query(usersRef, where("user_team.initiator_1", "==", { name: player_name, team: team_name }))
  const q6 = query(usersRef, where("user_team.initiator_2", "==", { name: player_name, team: team_name }))
  const q7 = query(usersRef, where("user_team.sentinel_1", "==", { name: player_name, team: team_name }))
  const q8 = query(usersRef, where("user_team.sentinel_2", "==", { name: player_name, team: team_name }))

  const q1Snapshot = await getDocs(q1);
  q1Snapshot.forEach((doc) => {
    var currPoints = doc.data().points
    var totalPoints = currPoints + newPoints
    setPointsForUser(doc.id, totalPoints)
  })
  const q2Snapshot = await getDocs(q2);
  q2Snapshot.forEach((doc) => {
    var currPoints = doc.data().points
    var totalPoints = currPoints + newPoints
    setPointsForUser(doc.id, totalPoints)
  })
  const q3Snapshot = await getDocs(q3);
  q3Snapshot.forEach((doc) => {
    var currPoints = doc.data().points
    var totalPoints = currPoints + newPoints
    setPointsForUser(doc.id, totalPoints)
  })
  const q4Snapshot = await getDocs(q4);
  q4Snapshot.forEach((doc) => {
    var currPoints = doc.data().points
    var totalPoints = currPoints + newPoints
    setPointsForUser(doc.id, totalPoints)
  })
  const q5Snapshot = await getDocs(q5);
  q5Snapshot.forEach((doc) => {
    var currPoints = doc.data().points
    var totalPoints = currPoints + newPoints
    setPointsForUser(doc.id, totalPoints)
  })
  const q6Snapshot = await getDocs(q6);
  q6Snapshot.forEach((doc) => {
    var currPoints = doc.data().points
    var totalPoints = currPoints + newPoints
    setPointsForUser(doc.id, totalPoints)
  })
  const q7Snapshot = await getDocs(q7);
  q7Snapshot.forEach((doc) => {
    var currPoints = doc.data().points
    var totalPoints = currPoints + newPoints
    setPointsForUser(doc.id, totalPoints)
  })
  const q8Snapshot = await getDocs(q8);
  q8Snapshot.forEach((doc) => {
    var currPoints = doc.data().points
    var totalPoints = currPoints + newPoints
    setPointsForUser(doc.id, totalPoints)
  })
}

export const addMatchData = async (MatchDayNum) => {
  const dbRef = ref(getDatabase());
  //change matches to teams
  await get(child(dbRef, "Teams"))
    .then((snapshot) => {
      snapshot.val().forEach((team) => {
        setDoc(doc(db, MatchDayNum, team.team_name), {});

        for (let i = 0; i < team.players.length; i++) {
          var rounds_won = team.match_score[0];
          var rounds_lost = team.match_score[1];
          var name = team.players[i].Name.split("\n")[0]
          //console.log(name)
          var newPoints = calculatePoints(
            team.players[i].K,
            team.players[i].D.split("/")[1],
            team.players[i].A,
            team.players[i].FK,
            team.players[i].FD,
            team.players[i].ADR,
            rounds_won,
            rounds_lost,
            team.players[i].Maps
          );
          updateDoc(doc(db, MatchDayNum, team.team_name), {
            players: arrayUnion({
              player_name: name,
              team_name: team.team_name,
              k: parseInt(team.players[i].K),
              d: parseInt(team.players[i].D.split("/")[1]),
              a: parseInt(team.players[i].A),
              fk: parseInt(team.players[i].FK),
              fd: parseInt(team.players[i].FD),
              adr: parseInt(team.players[i].ADR),
              points: newPoints,
            }),
          });
          addPoints(name, newPoints)
          updateAllUserPoints(name, team.team_name, newPoints)

        }
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addPlayoffMatchData = async (MatchDayNum) => {
  const dbRef = ref(getDatabase());
  //change matches to teams
  await get(child(dbRef, "Teams"))
    .then((snapshot) => {
      snapshot.val().forEach((team) => {
        setDoc(doc(db, MatchDayNum, team.team_name), {});

        for (let i = 0; i < team.players.length; i++) {
          var rounds_won = team.match_score[0];
          var rounds_lost = team.match_score[1];
          var name = team.players[i].Name.split("\n")[0];
          //console.log(name)
          var newPoints = calculatePlayoffPoints(
            team.players[i].K,
            team.players[i].D,
            team.players[i].A,
            team.players[i].FK,
            team.players[i].FD,
            team.players[i].ADR,
            rounds_won,
            rounds_lost,
            team.players[i].Maps,
            team.bonus
          );
          updateDoc(doc(db, MatchDayNum, team.team_name), {
            players: arrayUnion({
              player_name: name,
              team_name: team.team_name,
              k: parseInt(team.players[i].K),
              d: parseInt(team.players[i].D),
              a: parseInt(team.players[i].A),
              fk: parseInt(team.players[i].FK),
              fd: parseInt(team.players[i].FD),
              adr: parseInt(team.players[i].ADR),
              points: newPoints,
            }),
          });
          addPoints(name, newPoints)
          updateAllUserPoints(name, team.team_name, newPoints)

        }
      });
    })
    .catch((error) => {
      console.log(error);
    });
}

function calculatePoints(k, d, a, fk, fd, adr, rounds_won, rounds_lost, maps) {
  var x = 10;
  var y = 5;
  var combat_points =
    k * x -
    0.5 * d * x +
    0.5 * a * x +
    (fk - fd) * x +
    adr / 10;
  var rounds_points = (rounds_won - rounds_lost) * y;
  var points = Math.round((combat_points + rounds_points) / maps);
  return points;
}

function calculatePlayoffPoints(k, d, a, fk, fd, adr, rounds_won, rounds_lost, maps, bonus) {
  var x = 10;
  var y = 5;
  var combat_points =
    k * x -
    0.5 * d * x +
    0.5 * a * x +
    (fk - fd) * x +
    adr / 10;
  var rounds_points = (rounds_won - rounds_lost) * y;
  var points = Math.round((combat_points + rounds_points) / maps) + bonus;
  return points;
}

// export const doesCollectionExist = async (num) => {
//   const docRef = doc(db, "MatchesPlayed", "Matches")
//   const docSnap = await getDoc(docRef)
//   console.log(docSnap.data().match1)
//   return docSnap.data().match1
// };
export const getPoints = async (playerName) => {
  if (playerName === undefined || playerName === "") {
    return ""
  }
  var playerRef = doc(db, "Players", playerName);
  const docSnap = await getDoc(playerRef);
  if (docSnap.exists()) {
    return docSnap.data().total_points
  } else {
    console.log("no doc")
  }
}

export const setPoints = async (playerName, points) => {
  if (playerName === undefined || playerName === "") {
    return ""
  }
  var playerRef = doc(db, "Players", playerName);
  await updateDoc(playerRef, {
    "total_points": points
  })
}

export const setPointsForUser = async (userName, newPoints) => {
  if (userName === undefined) {
    //console.log("undef")
    return ""
  }
  var userRef = doc(db, "Users", userName);
  await updateDoc(userRef, {
    points: newPoints
  })
}

export const addPoints = async (playerName, points) => {
  if (playerName === undefined || playerName === "") {
    return ""
  }

  var playerRef = doc(db, "Players", playerName);
  try {
    await runTransaction(db, async (transaction) => {
      const playerDoc = await transaction.get(playerRef);
      if (!playerDoc.exists()) {
        //console.log("DNE")
        return
      }
      const newPoints = playerDoc.data().total_points + points
      transaction.update(playerRef, { total_points: newPoints })
    })
  } catch (e) {
    console.log(e)
  }
}

export const getUserByUID = async (uid) => {
  if (uid === null) {
    return "";
  }
  const userRef = doc(db, "Users", uid);
  const docSnap = await getDoc(userRef);
  if (docSnap.data()) {
    return docSnap.data().display_team_name;
  }
};

export const getUserList = async () => {
  var userList = [];
  const userSnapshot = await getDocs(collection(db, "Users"));
  userSnapshot.forEach((doc) => {
    userList.push(doc.data());
  });
  return userList;
};




export const getPlayer = async (player_name) => {
  
  if (player_name === null) {
    return ""
  }
  const playerRef = doc(db, "Players", player_name);
  const docSnap = await getDoc(playerRef);
  if (docSnap.data()) {
    return docSnap.data()
  }
}

// export const getUserPlayerList = async (uid) => {
//   if (uid === null) {
//     console.log("no uid");
//   }
//   const userRef = doc(db, "Users", uid);
//   const docSnap = await getDoc(userRef);
//   return docSnap;
// };

export const getPlayerList = async () => {
  var playersList = [];
  const playersSnapshot = await getDocs(collection(db, "Players"));
  playersSnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    playersList.push(doc.data());
  });
  return playersList;
};

// export const getPlayer = async (player_name) => {
//   const playerRef = doc(db, "Players", player_name);
//   const docSnap = await getDoc(playerRef);
//   if (docSnap.exists()) {
//     return docSnap.data()
//   }
  
// }
export const doesUserExist = async (uid) => {
  const userRef = doc(db, "Users", uid);
  const docSnap = await getDoc(userRef);
  return docSnap.exists();
};

export const doesUserHaveTeamName = async (uid) => {
  const userRef = doc(db, "Users", uid);
  const docSnap = await getDoc(userRef);
  return docSnap.data().user_team_name !== "";
};

export const getUserTeamName = async (uid) => {
  const userRef = doc(db, "Users", uid);
  const docSnap = await getDoc(userRef);
  return docSnap.data().display_team_name
}

export const addNewUser = async (uid, teamName, team, budget) => {
  setDoc(doc(db, "Users", uid), {
    user_team_name: teamName.toLowerCase(),
    display_team_name: teamName,
    // user_team : {
    //     controller_1: {team: "", name: ""},
    //     controller_2: '',
    //     duelist_1: '',
    //     duelist_2: '',
    //     initiator_1: '',
    //     initiator_2: '',
    //     sentinel_1: '',
    //     sentinel_2: ''
    // }
    user_team: team,
    points: 0,
    budget: budget,
    new_transfers: []
  });
  addNewTransfersListField(uid)
};

export const changeUserTeam = async (uid, team) => {
  const userRef = doc(db, "Users", uid)
  createBenchField(uid)
  await updateDoc(userRef, {
    "user_team" : team
  })
}

export const createBenchField = async (uid) => {
  const userRef = doc(db, "Users", uid)
  await updateDoc(userRef, {
    "user_team.bench_1" : {
      name : "",
      team : ""
    },
    "user_team.bench_2" : {
      name : "",
      team : ""
    },
    "user_team.bench_3" : {
      name : "",
      team : ""
    }
  })
}


export const getUserMatchInfo = async (match, team, name) => {
  const teamRef = doc(db, match, team);
  const docSnap = await getDoc(teamRef);
  var players = docSnap.data().players;
  for (var i = 0; i < players.length; i++) {
    if (players[i].player_name === name) {
      return players[i];
    }
  }
  return {
    a: 0,
    adr: 0,
    d: 0,
    fd: 0,
    fk: 0,
    k: 0,
    player_name: name,
    points: 0,
  };
};

export const doesTeamNameExist = async (name) => {
  var returnbool = false;
  const q = query(
    collection(db, "Users"),
    where("user_team_name", "==", name.toLowerCase())
  );
  const querySnap = await getDocs(q);
  querySnap.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    returnbool = true;
    return;
  });

  return returnbool;
};

export const getUserTeamByUID = async (uid) => {
  const userRef = doc(db, "Users", uid);
  const docSnap = await getDoc(userRef);
  return docSnap.data().user_team;
};

// export const updateTeamName = async (name, uid) => {
//     var userRef = doc(db, "Users", uid)
//     console.log()
//     try {
//         await runTransaction(db, async (transaction) => {
//             const userDoc = await transaction.get(userRef);
//             if (!userDoc.exists()) {
//                 throw "DNE"
//             }
//             transaction.update(userDoc, {

//                 user_team_name: name.toLowerCase()
//             })
//             transaction.update(userDoc, { display_team_name : name })

//         })
//     } catch (e) {
//         console.log(e)
//     }
// }

// export const generateUserDocument = async user => {

//     if (!user) {
//         console.log('generateUserDoc called but did not use firebase')
//         return
//     }
//     console.log('generateUserDocument called and used firebase read')
//     const userRef = firestore.doc(`users/${user.displayName}`)
//     const snapshot = await userRef.get();
//     if (!snapshot.exists) {
//         const { email, displayName } = user;
//         try {
//             await userRef.set({
//                 displayName,
//                 email,
//                 wins: 0,
//                 loses: 0,
//                 requested: [],
//                 pending: [],
//                 friends: [],
//             })
//         } catch (err) {
//             console.error('Error creating doc', err)
//         }
//     }

//     return getUserDoc(user.displayName)
// }

export const updateUserPoints = async (uid, points) => {
  const userRef = doc(db, "Users", uid);
  await updateDoc(userRef, {
    "points": points,
  });
};

export const addPickedCount = async (count, player_name) => {
  const playerRef = doc(db, "Players", player_name);
  await updateDoc(playerRef, {
    "picked": count,
  });
};

export const getPickedCount = async (player_name) => {
  
  const player = await getDoc(doc(db, "Players", player_name))
  return(player.data().picked)
}

export const updatePicked = async (playerName) => {
  if (playerName === undefined || playerName === "") {
    return ""
  }

  var playerRef = doc(db, "Players", playerName);
  try {
    await runTransaction(db, async (transaction) => {
      const playerDoc = await transaction.get(playerRef);
      if (!playerDoc.exists()) {
        //console.log("DNE")
        return
      }
      const newPicked = playerDoc.data().picked + 1
      transaction.update(playerRef, { picked: newPicked })
    })
  } catch (e) {
    console.log(e)
  }
}

export const addBudget = async () => {
  const usersSnapshot = await getDocs(collection(db, "Users"));
  usersSnapshot.forEach((user) => {
    var total = getTotalSpent(user.id)
    total.then((value) => {
      var money = 13000 - value
      console.log(user.data().user_team_name + ": " + money)
      addMoney(money, user.id)
    })
  })
}

export const addMoney = async (money, uid) => {
  const userRef = doc(db, "Users", uid);
  await updateDoc(userRef, {
    "budget": money,
  })
}

export const getTotalSpent = async (uid) => {
  const user = await getDoc(doc(db, "Users", uid))
  var total = 0;
  var price1 = parseInt(await getPlayerPrice(user.data().user_team.controller_1.name))
  var price2 = parseInt(await getPlayerPrice(user.data().user_team.controller_2.name))
  var price3 = parseInt(await getPlayerPrice(user.data().user_team.duelist_1.name))
  var price4 = parseInt(await getPlayerPrice(user.data().user_team.duelist_2.name))
  var price5 = parseInt(await getPlayerPrice(user.data().user_team.initiator_1.name))
  var price6 = parseInt(await getPlayerPrice(user.data().user_team.initiator_2.name))
  var price7 = parseInt(await getPlayerPrice(user.data().user_team.sentinel_1.name))
  var price8 = parseInt(await getPlayerPrice(user.data().user_team.sentinel_2.name))
  total = price1 + price2 + price3 + price4 + price5 + price6 + price7 + price8
  return total
}

export const getPlayerPrice = async (player_name) => {
  var playerRef = doc(db, "Players", player_name);
  const docSnap = await getDoc(playerRef);
  if (docSnap.exists()) {
    return docSnap.data().price
  } else {
    console.log("no doc")
  }
}

export const getBudget = async (uid) => {
  var userRef = doc(db, "Users", uid);
  const docSnap = await getDoc(userRef);
  if (docSnap.exists()) {
    return docSnap.data().budget
  } else {
    console.log("no doc")
  }
}

export const setTransfersForAll = async () => {
  const usersSnapshot = await getDocs(collection(db, "Users"));
  usersSnapshot.forEach((user) => {
    console.log(user.id)
    var transferCount = 1
    if (user.data().user_team.controller_1.name === "ec1s" || user.data().user_team.controller_2.name === "ec1s") {
      transferCount = 2
    }
    if (user.data().user_team.sentinel_1.name === "BabyJ" || user.data().user_team.sentinel_2.name === "BabyJ") {
      transferCount = 2
    }
    if ((user.data().user_team.sentinel_1.name === "BabyJ" || user.data().user_team.sentinel_2.name === "BabyJ") && 
        (user.data().user_team.controller_1.name === "ec1s" || user.data().user_team.controller_2.name === "ec1s")) {
      transferCount = 3
    }
    console.log(user.id + ": " + transferCount)
    addTransferField(user.id, transferCount)
  })
}

export const addTransferField = async (uid, transfers) => {
  var userRef = doc(db, "Users", uid)
  await updateDoc(userRef, {
    "transfers": transfers
  })
}

export const getTransfers = async (uid) => {
  var userRef = doc(db, "Users", uid);
  const docSnap = await getDoc(userRef);
  if (docSnap.exists()) {
    return docSnap.data().transfers
  } else {
    console.log("no doc")
  }
}

export const setTransfersFinal = async (uid) => {
  var userRef = doc(db, "Users", uid)
  await updateDoc(userRef, {
    "transfers": -1
  })
  return true
}

export const addNewTransfersList = async (uid, players) => {
  var userRef = doc(db, "Users", uid)
  await updateDoc(userRef, {
    "user_team.new_transfers" : players
  })
}

export const addNewTransfersListField = async (uid) => {
  var userRef = doc(db, "Users", uid)
  await updateDoc(userRef, {
    "user_team.new_transfers" : []
  })
}

export const addNewTransfersListFieldForAll = async () => {
  const usersSnapshot = await getDocs(collection(db, "Users"));
  usersSnapshot.forEach((user) => {
    if (!user.data().user_team.new_transfers) {
      addNewTransfersListField(user.id)
    }
  })
}

export const resetAllPlayerPoints = async () => {
  const playerSnapshot = await getDocs(collection(db, "Players"));
  playerSnapshot.forEach((player) => {
    setPlayerPoints(player.id, 0)
  })
}

export const setPlayerPoints = async (player, points) => {
  var playerRef = doc(db, "Players", player)
  await updateDoc(playerRef, {
    "total_points": points
  })
}