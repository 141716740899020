import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Nav } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Button, Modal } from 'react-bootstrap';
import { FaDiscord, FaTwitter, FaCoffee } from 'react-icons/fa';
import { AiFillMessage } from 'react-icons/ai';
import { Text, Linking } from 'react-native'


export default function Banner() {
  const [showModal, setShowModal] = useState(false) //render when the page first opens
  const [showMessage, setShowMessage] = useState(false)

  return (
    <Navbar variant="dark">
      <Navbar.Brand href="/">
        <h1 style={{ paddingLeft: "15%" }}>
          <Image
            style={{ width: 50, height: 50 }}
            src="https://owcdn.net/img/603bfd7bf3f54.png"
          />
          { } Fantasy VCT
        </h1>
      </Navbar.Brand>

      <div style={{ paddingLeft: "6%" }}>
        <Nav className="me-auto">

          <Button style={{ maxHeight: "40px" }} variant="warning" onClick={() => setShowModal(true)}>
            Rules
          </Button>
          <Button style={{ maxHeight: "40px" }} variant="outline-warning" onClick={() => setShowMessage(true)}>
            <AiFillMessage size={25}/>
          </Button>

          <Nav.Link href="/">&nbsp;&nbsp; My Team &nbsp;&nbsp;</Nav.Link>
          <Nav.Link href="/leaderboard">&nbsp;&nbsp; Leaderboard &nbsp;&nbsp;</Nav.Link>
          <Nav.Link href="/players">&nbsp;&nbsp; Players &nbsp;&nbsp;</Nav.Link>
          {/* <Nav.Link href="/transfers">&nbsp;&nbsp; Transfers &nbsp;&nbsp;</Nav.Link> */}
          <Nav.Link href="https://discord.gg/FvAgKUYCbP">&nbsp;&nbsp;
            <FaDiscord size={30} />
            &nbsp;&nbsp;
          </Nav.Link>
          <Nav.Link href="https://twitter.com/FantasyVCT">&nbsp;&nbsp;
            <FaTwitter size={30} />
            &nbsp;&nbsp;
          </Nav.Link>
          <Nav.Link href="https://ko-fi.com/fantasyvct">&nbsp;&nbsp;
            <FaCoffee size={30} />
            &nbsp;&nbsp;
          </Nav.Link>

          <Modal show={showMessage} onClick={() => setShowMessage(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Messages & Updates</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <p>
                <strong>Apr 24: </strong> Fantasy VCT for Masters 1 Reykjavik is over! OpTic Gaming take the first international title of the year beating out LOUD in the Grand finals. It was a super entertaining event full of upsets and underdog runs which made our fantasy league just as unpredictable.
                <br/>
                <br/>
                The winners are <br />
                🥇 : Prepd (5405) <br />
                🥈 : squad (4837) <br />
                🥉 : Barre Copium (4821) <br />
                <br />
                Thank you everyone for participating for this tournament, I hope you all had fun with it. Of course, we'll be back with Challengers 2 with a possibility of multiple regions supported. No promises on that yet, but stay tuned!
                <br />
                <br />
                If you are one of the winners you can DM me on Discord. Otherwise I will be reaching out in the next few days with details on how to collect your prize.
              </p>

              <p>
                <strong>Apr 10: </strong> We've made a change to display current stats for Group matches before the completion of the stage. 
                This means all player points are subject to change and your team points will not be reflected on the Leaderboard until all Group Matches are over.
              </p>
              <p>
              <strong>Apr 10: </strong> Team Registration is closed as Masters Reykjavik begins today! 
              We got over 700 teams this time which is absolutely insane, you guys are crazy, and I thank all of you for participating.
              <br/>
              <br/>
              Remember to join the rest of the community in the <Text style ={{color: 'blue', fontSize: 15}}onPress={() => Linking.openURL("https://discord.gg/FvAgKUYCbP")}> Discord Server </Text>
              to talk about the tournament and rep your favorite teams.
              <br/>
              <br/>
              We'll be running more polls for your input and events like community 10-mans so you don't want to miss out.
              <br/>
              <br/>
              You might have seen the little coffee icon on the top of the page. That's if you would like to support us and keep the site running. 
              At the moment, all of the server costs and prize pool money is coming straight out of our pockets. 
              We love running this competition and will keep doing so but would greatly appreciate any donations.
              <br/>
              <br/>
              Thanks again everyone and good luck to your teams!
              </p>

              <p>
                <strong>Apr 7: </strong> Welcome back to Fantasy VCT! This tab will continue to be where we communicate on the site itself. 
                If you want to chat to the team, other participants, or give feedback/input, join our <Text style ={{color: 'blue', fontSize: 15}}onPress={() => Linking.openURL("https://discord.gg/FvAgKUYCbP")}> Discord Server </Text>
                <br/>
                <br/>
                Members of the server got the chance to vote on player prices for this competition, which will continue in the future so make sure to get in and give your input!
                <br/>
                <br/>
                We also have a <Text style ={{color: 'blue', fontSize: 15}}onPress={() => Linking.openURL("https://twitter.com/FantasyVCT")}> Twitter </Text> where we post general updates.
                <br/>
                <br/>
                As always, thanks for playing and good luck!
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowMessage(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showModal} onClick={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Fantasy VCT Champions Rules</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5> <strong>How the team selection works: </strong></h5>
              <p>
                  - You have a salary budget of 13000 credits to recruit your players.<br />
                  - You must recruit 2 players for each role (Duelist, Initiator, Sentinel, Controller).<br />
                  - The roles each player can be recruited as is listed.<br />
              </p>
              <p>
                  NOTE: Keep in mind Derke may be rejoining Fnatic at some point in the tournament, replacing his sub H1ber. 
                  Since there are no transfers, if you pick one of H1ber/Derke, the points you earn will depend on who actually plays.
                  <br/>
                  <strong>You will not be able to sub out either player.</strong>
              </p>
              <br />
              <h5><strong>How the fantasy points work: </strong></h5>
              <p>
                  - Each player will earn you fantasy points every matchweek through the group stage (one cumulative match day) and then the playoffs (maximum of 6 match days)
                  <br/>
                  NOTE: This format means any team eliminated in the group stage will only earn ONE match day's worth of points so keep that in mind as you're picking your teams.
              </p>
              <p>
                  <br />
                  - Players earn points through combat stats (K/D/A, FK/FD, ADR) and round stats (Rounds won, Rounds lost).
                  <br />
                  - The points tally for a match day is an average of points earned per map played.
                  <br />
                  <br />
                  - Points Calculation: <br />
                  <em>
                  [+10 Points per kill, +5 Points per assists, +10 Points per First Kill, +ADR/10] <br />
                  [-5 Points per death, -10 Points per First Deaths] <br />
                  [+5 Points per Round win, -5 Points per Round loss] <br />
                  <br />
                  </em>
                  <br />
                  <br />
                  <strong>
                  Your final points tally is the total sum of all points earned by players across every (6) match day of the event
                  </strong>
              </p>
              <br />
              <br />
              <br />
              <div style={{ textAlign: "center" }}>
                  <h3> The Winners’ prize pool is: </h3>
                  <h4>1st Place: 3650VP ($35)</h4>
                  <h5>2nd Place: 2050VP ($20)</h5>
                  <h6>3rd Place: 1000VP ($10)</h6>
                  *note: you must have paypal to receive your payment
                  <br />
                  <p><em>(Must have a PayPal in order to claim prize.)</em>  </p>
                  <br />
                  <h6> Thank you for trying out our Fantasy VCT!</h6>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>



        </Nav>
      </div>
    </Navbar >
  )
}